.centerContent{
    margin: 0 auto;
    width: 75%;
}

.spanLocation{
    color:red;
    color: #ffc107;
}

.buttonExport{
    background-color:#171717;
    height: 3rem;
    width: 10rem;
    color: #ffc107;
    border-radius: 10px;
}

.contentTitle{
    display: flex;
    justify-content: space-between;
}

.contentDataAndMeasure{
    background-color: #ebe6e6;
    margin: 0.5rem 0rem;
    border-radius: 10px;
}

.list{
    width: 100%;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    list-style-type: none;
}

.spacer{
    display: inline;
}

.titles{
    font-weight: 700;
    margin-left: 0rem;
}

.textTitle{
    white-space: nowrap;
    width: 100%;                 
    overflow: hidden;       
    -o-text-overflow: ellipsis;   
    text-overflow:    ellipsis;
    margin-bottom: 0rem;
}

.imgs{
    width: 3rem;
    margin-top: 1rem;
}

.contentHistograma{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.contentHistograma>* {
    flex: 3 2 30rem;
}

.duoData{
    display: flex;
    justify-content: center;
    width: 40%;
}

.trioData{
    display: flex;
    justify-content: center;
    width: 60%;
}

.contentAData{
    background-color:#171717;
    width: 20rem;
    border-radius: 10px;
    margin: 1rem ;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    padding: 0rem 0.5rem;
    position: relative;
    height: 8rem;
}

.aDataH3{
    font-size: 1rem;
    color: #fff;
    margin-left: 0.3rem;
    padding: 1rem;
}

.bottomDate{
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    position:absolute;
    bottom: 0;
    margin-bottom: 0.5rem;
}

.contentGraphs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
}

.graphmeasures{
    width: 100%;
    margin: 1rem;
}

.graph{
    width: 40%;
    flex: 1 1 20rem;
    margin: 1rem;
}

.li{
    padding: 0.2rem
}

.linkLocation{
    text-decoration: none;
    color:#807e7e;
}

.selectorType{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
}

.filters{
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 20rem;
    align-items: center;
}

.typeSelector{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 10rem;
}

.dateFilter{
    height: 2.5rem;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    margin: 1rem 0.5rem 1rem 0.5rem;
}

.selectTemplate{
    height: 2.5rem;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    width: 10rem;
    color: #171717;
    margin-left: 0.5rem; 
}

.buttonClick{
    background-color: #171717;
    height: 2.5rem;
    color: #ffc107;
    border-radius: 10px;
    margin: 1rem 0.5rem 1rem 0.5rem;
}

.list{
    grid-template-columns: 35% 25% 20% 20%;
    padding-left: 0;
    margin-bottom: 0;
}

.dateSelector{
    display: flex;
    align-items: center;
    min-width: 10rem;
}

@media (max-width: 767px){
    .centerContent{
        width: 90%;
        font-size: 0.7rem;
    }

    .contentDataAndMeasure{
        font-size: 0.6rem;
        padding: 0.5rem 0.2rem;
    }

    .contentAData{
        margin: 0.2rem;
        height: 5.5rem;
        width: 10rem;
        padding: 0 0.5rem;
    }

    .title{
        font-size: 1.5rem;
        margin-bottom: 0;
    }

    .buttonExport{
        height: 2.5rem;
        width: 4rem;
    }

    .aDataH3{
        font-size: 0.6rem;
        padding: 1rem 0.2rem;
    }

    .imgs{
        width: 1.5rem;
    }

    .bottomDate{
        font-size: 1rem
    }

    .dateFilter{
        height: 2rem;
    }

    .selectTemplate{
        height: 2rem;
        width: 7rem;
    }

    .buttonClick{
        height: 2rem;
    }
}