.footer{
    background-color: #424242;
    color: #fff;
    height: 7rem;
    font-size: 0.8rem;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentFooter{
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 767px){
    .footer {
        height: 5rem;
        font-size: 0.5rem;
    }
}