.filters{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.datefilter{
    display: grid;
    grid-template-columns: 15% 35% 15% 35%;
    align-content: center;
    padding: 1rem 0rem 0.5rem 0;
}

.dispfilter{
    display: grid;
    min-width: 22rem;
    grid-template-columns: 28% 42% 30%;
    align-content: center;
    align-items: center;
    padding: 1rem 0rem 0.5rem 0;
}

.input{
    width: 100%;
    max-width: 9rem;
    min-width: 3rem;
    height: 2.5rem;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
}

.buttonClickTemplates{
    background-color: #171717;
    height: 2.5rem;
    color: #ffc107;
    border-radius: 10px;
    margin: 0.2rem;
    max-width: 10rem;
}

.select{
    display: flex;
    flex: 1 1 20%;
    align-items: center;
    color: #171717;
}

.selectDisp{
    height: 2.5rem;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    max-width: 15rem;
    margin: 0.2rem
}

@media (max-width: 767px){
    .dispfilter{
        min-width: 12rem;
    }

    .input{
        max-width: 6rem;
        height: 2rem;
    }

    .buttonClick{
        height: 2rem;
    }

    .selectDisp{
        height: 2rem;
    }
}