.backgroundViewControl{
    margin: 0 auto;
    width: 75%;
}

.span{
    color: #ffc107;
}

.contentFilter{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}

.contentViewControl{
    height: 100%;
    min-height: 80vh;
    background-color: #fafafa;
}

.site{
    padding: 4rem 0;
    color: #807e7e;
    margin: 0;
}

.viewerRows{
    padding-top: 1.5rem;
    width: 6rem;
}

.ul{
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.elementLi{
    margin: 0 0.2rem;
    padding-top: 1.5rem;
    cursor: pointer;
}

.select{
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    width: 20%;
    color: #171717;
}

.panel{
    width: 80%;
}

.selectPages{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
}

.contentFilter>* {
    flex:1 1 10rem;
}

@media (max-width: 767px){
    .backgroundViewControl{
        width: 90%;
    }

    .contentViewControl{
        font-size: 0.7rem;
    }

    .select{
        margin: 0.5rem;
    }

    .title{
        font-size: 1.5rem;
        margin-bottom: 0;
    }
}