.contentSelector{
    width: 100%;
    margin-top: 2rem;
    margin: 0 0;
    display: flex;
}

.individual{
    display: flex;
    flex-direction: row;
    padding: 1rem 0rem 0.5rem 0;
    width: 40%;
}

.input{
    width: 100%;
    max-width: 9rem;
    min-width: 3rem;
    height: 2.5rem;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
}

.buttonClick{
    background-color: #171717;
    height: 2.5rem;
    width: 20%;
    color: #ffc107;
    border-radius: 10px;
    margin: 1rem 1.5rem 1rem 0.5rem;
}

@media (max-width: 767px){
    .input{
        max-width: 6rem;
        height: 2rem;
    }

    .buttonClick{
        height: 2rem;
    }
}