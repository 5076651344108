.sidebarOpen {
    position: fixed;
    display:flex;
    z-index: 1;
    height: 100%;
    top: 0;
    width: 10rem;
    transform: translateX(0rem);
    background-color: #171717;
    transition: all 0.3s ease-in-out;
  }

  .sidebarClose {
    position: fixed;
    display:flex;
    z-index: 1;
    height: 100%;
    top: 0;
    width: 10rem;
    transform: translateX(-10rem);
    background-color: #171717;
    transition: all 0.3s ease-in-out;
  }

.content {
    padding-top: 30vh;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.toggleButtonOpen {
    position: fixed;
    z-index: 1;
    background-color: #171717;
    padding: 0.5rem;
    border-radius:0px 8px 8px 0px;
    top: 50%;   
    transition: all 0.3s ease-in-out;
    cursor:pointer;
    transform: translateX(10rem)
}

.toggleButtonClose {
    position: fixed;
    z-index: 1;
    background-color: #171717;
    padding: 0.5rem;
    border-radius:0px 8px 8px 0px;
    top: 50%;   
    transition: all 0.3s ease-in-out;
    cursor:pointer;
}

.arrow {
    transform: rotate(-90deg);
    width: 2rem; 
}

.ulist {
    padding-left: 0.2rem;
}

.link {
    color: white;
    text-decoration: none;
}

.liSidebar {
    padding:2px 0;
    border-top: 1px solid white;
}

@media (max-width: 767px){
    .sidebarOpen{
        font-size: 0.7rem;
        width: 8rem;
    }

    .sidebarClose{
        font-size: 0.7rem;
        width: 8rem;
        transform: translateX(-8rem);
    }

    .toggleButtonOpen{
        transform: translateX(8rem)
    }
}