.contentNav{
    background-color: #171717;
    height: 8rem;
    display: flex;
    align-items: center;
    z-index: 2;
    width: 100%;
}

.subContentNav{
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoEcofor{
    width: 20rem;
}

.contentMenu{
    width: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}

.user{
    font-size: 1.2rem;
    font-weight: 200;
    color: #fff;
}

.menuImage{
    width: 64px;
    height: fit-content;
    cursor: pointer;
}

.navbarmenu{
    background-color: #171717;
    border-style: none;
}

@media (max-width: 767px){
    .contentNav{
        height: 4rem;
    }

    .subContentNav{
        width: 90%;
    }

    .logoEcofor{
        width: 10rem;
    }

    .contentMenu{
        padding-top: 0.5rem;
        width: 8rem;
    }

    .user{
        font-size: 0.8rem
    }

    .menuImage{
        width: 32px;
    }

}