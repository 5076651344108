.table{
    background:#fff;
    width: 100%;
    margin: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 0.9rem;
}

.fraction{
    display: flex;
    padding: 0.2rem;

    &:hover {
        background-color:blanchedalmond
    }
}

.headers{
    display: flex;
    background:#ffc107;
    padding: 0.2rem;
    border-radius:8px 8px 0 0;
}

.headersText{
    font-size: 1.1rem;
    color:#454545;
    text-align: center;
    border-style: none;
    min-width: 9rem;
}

.headersTextmin{
    font-size: 1.1rem;
    color:#454545;
    text-align: center;
    border-style: none;
    min-width: 1.5rem;
}

.headersTextXL{
    font-size: 1.1rem;
    color:#454545;
    text-align: center;
    border-style: none;
    min-width: 16rem;
}

.tdText{
    text-align: center;
    border-style: none;
    min-width: 9rem;
    overflow: hidden;       
    -o-text-overflow: ellipsis;   
    text-overflow:    ellipsis;
}

.tdTextmin{
    text-align: center;
    border-style: none;
    min-width: 1.5rem;
}

.tdTextXL{
    text-align: center;
    border-style: none;
    min-width: 16rem;
    overflow: hidden;       
    -o-text-overflow: ellipsis;   
    text-overflow:    ellipsis;
}

.buttonDetails{
    background-color: #ffc107;
    border:none;
    border-radius: 5px;
    padding: 0 0.5rem;
    text-decoration: none;
    color:black;
}

.spaceWithe{
    border-radius:0px 0px 8px 8px;
    padding: 1rem;
}

.nControl{
    width: -1rem;
}

.subtable{
    background:#fff;
    width: 90%;
}

.subheaders{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #ffd966;
    display: flex;
    border-radius:8px 8px 0 0;
}

.subfraction{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;

    &:hover {
        background-color:blanchedalmond
    }
}

.subheadersText{
    text-align: center;
}

.inputModal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.7rem 0;
}

.inputSearchModal{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
    width: 16rem;
    margin: 0 0 0 0.5rem
}

.modalButton{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717
}

.modalDeleteButton{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
    background-color: #FF9B85;
}

@media (max-width: 767px){
    .headers {
        padding: 0.2rem;
    }

    .fraction {
        padding: 0.2rem;
        font-size: 0.6rem;
    }

    .headersText {
        font-size: 0.8rem;
    }

    .headersTextmin {
        font-size: 0.8rem;
    }

    .headersTextXL {
        font-size: 0.8rem;
    }

    .tdText {
        padding: 0.2rem;
    }

    .tdTextmin {
        padding: 0.2rem;
    }

    .tdTextXL {
        padding: 0.2rem;
    }

}