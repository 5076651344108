.text{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.3rem;
}

.principalContent{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: 70%;
    overflow-x: hidden;
    overflow-y: visible;
}

.contentSlideShow{
    display: flex;
    justify-content: space-evenly;
    height: 2rem;
    width: 50%;
    align-items: center;
    overflow: hidden;
    margin-top: 1rem;
}

.slide{
    width: 1rem;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    
    &:hover {
        transform: scale(1.1);
    }
    
}

.controls{
    position: absolute;
    top: 75%;
    left: 50%;
    z-index: 15;
    pointer-events: none;
}

.buttonNextR{
    pointer-events: all;
    background: rgb(139, 139, 139);
    border: none;
    border-radius: 30px;
    padding: 0.3rem;
    width: 30px;
    outline: none;
    /*position: absolute;*/
    transition: 0.9s ease all;
    cursor: pointer;
    margin: 1rem 0rem;
    
}

.buttonNextL{
    pointer-events: all;
    background: rgb(139, 139, 139);
    border: none;
    border-radius: 30px;
    padding: 0.3rem;
    width: 30px;
    outline: none;
    /*position: absolute;*/
    transition: 0.9s ease all;
    cursor: pointer;
    margin: 1rem 0rem;
    
}

.contentSlide{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
}

.nElements{
    width: 15%;
    margin-top: 1rem;
}

@media (max-width: 767px){
    .contentSlide{
        width: 85%;
        font-size: 0.6rem;
      }
}