.backgroundView {
    margin: 0 auto;
    width: 75%;
}

.contentFilter{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    margin: 0 0 1rem 0;
    align-items: center;
    justify-content: space-between;
}

.contentViewContol{
    height: 100%;
    min-height: 80vh;
    background-color: #fafafa;
}

.navtabs{
    padding-top: 2rem;
    width: 27rem;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.navtab{
    float: left;
    padding: 0.5rem;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: -1px; 
    text-align: center;
    cursor: pointer;

    &:hover{
        background-color: #ddd;
    }
}

.navtabSelected{
    float: left;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-bottom: 1px solid #fafafa;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: -1px; 
    text-align: center
}

.titlediv{
    padding: 1rem 0 0 0;
    display: flex;
    justify-content: space-between
}

.title{
    margin-bottom: 1rem;
}

.buttonCreate{
    background-color: #171717;
    max-width: 8rem;
    color: #ffc107;
    border-radius: 10px
}

.buttonSearch{
    background-color: #171717;
    max-width: 5rem;
    color: #ffc107;
    border-radius: 10px;
    margin-left: 0.5rem
}

.filter{
    display: inline-block;
}

.inputSearch{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
    width: 16rem;
    margin: 0 0 0 0.5rem
}

.selectPages{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
}

.select{
    display: flex;
    justify-content: flex-end;
    color: #171717;
}

.dialog{
    background-color: #fafafa;
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
    padding: 0 0 1rem 0
}

.titleModal{
    padding: 0.5rem 0 0.2rem 0.5rem;
    background-color: #ffc107;
}

.contentModal{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    /* margin: 1rem 2rem 2rem 2rem; */
}

.buttonsModal{
    display: flex;
    justify-content: center;
}

.buttonConfirm{
    border-radius: 6px;
    border: 0.1px solid #171717;
    color: #171717;
    background-color: #60D394;
    /* background-color: #ffc107; */
    /* background-color: #ddd; */
    /* background-color: #FFD97D; */
}

.buttonCancel{
    border-radius: 6px;
    border: 0.1px solid #171717;
    color: #171717;
    background-color: #FF9B85;
}

.buttonModal{
    background-color: #171717;
    color: #ffc107;
    border-radius: 6px;
}

.inputModal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.7rem 0;
}

.inputSearchModal{
    border-radius: 6px;
    border: 0.1px solid #c7c7c7;
    color: #171717;
    width: 16rem;
    margin: 0 0 0 0.5rem
}

@media (max-width: 767px){
    .backgroundView{
        width: 90%;
    }

    .contentViewContol{
        font-size: 0.7rem;
    }

    .navtabs{
        width: 17rem;
    }

    .navtab{
        padding: 0.1rem;
    }

    .navtabSelected{
        padding: 0.1rem;
    }

    .title{
        font-size: 1.5rem;
    }

    .buttonCreate{
        height: 1.5rem;
        max-width: 6rem;
    }

    .buttonSearch{
        margin-left : 0.2rem;
    }

    .inputModal{
        display: flex;
        justify-content: space-between;
        width: 15rem;
        margin-left: 0.2rem;
    }

    .inputSearch{
        width: 7rem;
    }

    .inputSearchModal{
        width: 12rem;
        margin-left: 0.2rem;
    }

}